<template>
  <div class="conOfAllSidebar">
    <section class="conSideCard conSideFullScreen fill-height hidden-md-and-down">
      <v-card class="mx-auto cardCon fill-height scrollbar" elevation="1">
        <div class="conAllBtn">
          <v-btn
            :to="{
              name: 'allProductsReport',
            }"
            :ripple="false"
            class="allProductButton"
            text
            :class="{ active: $route.name == 'allProductsReport' }"
          >
            All
          </v-btn>
        </div>

        <div
          class="eachimgItem"
          v-for="product in productsList"
          :key="product.id"
        >
          <v-btn
            @click="changeRoute(product.id, $route.query.year)"
            text
            :ripple="false"
            class="productButton"
            :class="{ active: product.id == $route.params.id }"
          >
            <div class="item">
              <img
                :src="require(`@/assets/img/products/${product.img_name}`)"
                alt="product item"
              />
            </div>
          </v-btn>
        </div>
      </v-card>
    </section>
    <section class="conSideCard conSideRsponsive fill-height hidden-lg-and-up" >
      <v-card class="mx-auto cardCon fill-height scrollbar d-flex" elevation="1">
        <div class="conAllBtn">
          <v-btn
            :to="{
              name: 'allProductsReport',
            }"
            :ripple="false"
            class="allProductButton"
            text
            :class="{ active: $route.name == 'allProductsReport' }"
          >
            All
          </v-btn>
        </div>

        <div
          class="eachimgItem"
          v-for="product in productsList"
          :key="product.id"
        >
          <v-btn
            @click="changeRoute(product.id, $route.query.year)"
            text
            :ripple="false"
            class="productButton"
            :class="{ active: product.id == $route.params.id }"
          >
            <div class="item">
              <img
                :src="require(`@/assets/img/products/${product.img_name}`)"
                alt="product item"
              />
            </div>
          </v-btn>
        </div>
      </v-card>
    </section>
  </div>
</template>

<script>
// import { ApiService } from "@/modules/shared/services/api";
// let apiServices = new ApiService();

export default {
  data: () => ({
    productsList: [
      {
        id: 1,
        img_name: "product-1.png",
      },
      {
        id: 2,
        img_name: "product-2.png",
      },
      {
        id: 3,
        img_name: "product-3.png",
      },
      {
        id: 4,
        img_name: "product-4.png",
      },
      {
        id: 5,
        img_name: "product-5.png",
      },
      {
        id: 6,
        img_name: "product-6.png",
      },
    ],
  }),

  methods: {
    changeRoute(id, year) {
      console.log(id, year);
      this.$router.push({
        name: "productsReport",
        params: { id: id },
        query: { year: year },
      });
      // this.$router.replace({
      //   name: "productsReport",
      //   query: { id: id,  year: this.selectedYear },

      // });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./_sideBarProducts.scss";
</style>
